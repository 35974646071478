import React, { useRef } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import FeaturedList from "../components/common/featuredlist";
import Review from "../components/common/review";
import TowerAdventure from "../components/tower/TowerAdventure";
import YourGuides from "../components/common/yourGuides";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import CompaniesGuided from "../components/common/companiesGuided";
import useIsVisible from "../hooks/isVisible";
import SliderAndContent from "../components/common/SliderAndContent";
import PanamaAdventure from "../components/adventure/panama_adventure";
import Seo from "../components/seo";
import Faqs from "../components/home/faq";

const Adventures = ({ data: { sanityAdventuresPage, sanitySitesettings } }) => {
  const elemRef = useRef();

  const isVisible = useIsVisible(elemRef);
  let seo;
  let hero;
  let breadcrumb;
  let features;
  let review;
  let adventure;
  let beingSection;
  let company;
  let guide;
  let faq;

  sanityAdventuresPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "featureList") {
      features = element;
    } else if (element._type === "reviewSection") {
      review = element;
    } else if (element._type === "towerAdvantureSection") {
      adventure = element;
    } else if (element._type === "guidesSection") {
      guide = element;
    } else if (element._type === "beingSection") {
      beingSection = element;
    } else if (element._type === "companyGuided") {
      company = element;
    } else if (element._type === "faq") {
      faq = element;
    }
  });
  return (
    <Layout>
      <Seo data={seo} />
      <Hero heroSection={hero} />
      <Logo logo={sanitySitesettings?.logo} />

      {features && <FeaturedList data={features} />}

      {review && <Review data={review} />}

      {adventure && <TowerAdventure data={adventure} />}
      <div ref={elemRef}>
        {isVisible && (
          <>
            {sanityAdventuresPage?.contentslider?.map((item, index) => (
              <SliderAndContent
                data={item}
                key={index}
                sliderId={`adventures-${index}`}
                show="show-up"
              />
            ))}

            {beingSection && (
              <PanamaAdventure
                data={beingSection}
                bgUrl={`linear-gradient(to bottom,rgba(192, 192, 192, 0.90),rgba(121, 121, 121, 0.90)),url(${beingSection?.sectionBg?.asset.url})`}
                bgSize=""
                minH="min-h-[110vh]"
                show="show-up"
              />
            )}
            {guide && <YourGuides data={guide} show="show-up" />}
            {faq?.heading && faq?.faqList.length > 0 && <Faqs data={faq} />}

            {breadcrumb && <BreadCrumb path={breadcrumb} show="show-up" />}
            <Instagram />
            {company && (
              <>
                <CompaniesGuided data={company} show="show-up" />
                <Logo logo={sanitySitesettings?.logo} />
              </>
            )}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Adventures;

export const query = graphql`
  query {
    sanityAdventuresPage {
      pageBuilder {
        ... on SanityFaq {
          _type
          heading
          faqList {
            question
            _rawAnswer
          }
        }
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          alignment
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityFeatureList {
          _type
          featureItems {
            icon
            title
          }
        }
        ... on SanityReviewSection {
          _type
          comment
          subHeading
          subHeading2
          heading
          _rawAuthor
          sectionBg {
            asset {
              url
            }
          }
          ctaButton1 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
        }
        ... on SanityTowerAdvantureSection {
          _type
          heading
          videoUrl
          youtubeId
          coverImg {
            asset {
              gatsbyImageData(
                fit: FILLMAX
                placeholder: BLURRED
                formats: WEBP
                width: 770
              )
            }
          }
          ctaButton1 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
        }
        ... on SanityBeingSection {
          _type
          ctaButton1 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          ctaButton2 {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          heading
          link
          subHeading
          svg
          _rawDetail
          sectionBg {
            asset {
              url
            }
          }
        }
        ... on SanityCompanyGuided {
          _type
          heading
          sectionBg {
            asset {
              url
            }
          }
          list {
            name
          }
        }

        ... on SanityGuidesSection {
          _type
          quote1
          firstQuote
          quote2
          secondQuote
          title
          sliderImage {
            guidance
            leader
            name
            photo {
              asset {
                _id
                metadata {
                  blurHash
                }
              }
              alt
              crop {
                bottom
                left
                right
                top
              }
              hotspot {
                x
                y
              }
            }
          }
        }
      }
      contentslider {
        title
        bgRepeat
        bgSize
        direction
        height
        overlay
        size
        textColor
        paragraphTitle
        sectionBG {
          asset {
            url
          }
        }
        ctaButton1 {
          link
          title
          type
          variant
          formId
          downloadLink
        }
        ctaButton2 {
          link
          title
          type
          variant
          formId
          downloadLink
        }
        ctaButton3 {
          link
          title
          type
          variant
          formId
          downloadLink
        }
        sliderImages {
          alt
          asset {
            gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
          }
        }
        _rawContent
        _rawContent2
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
      }
    }
  }
`;
