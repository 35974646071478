import React from "react";
import { Link } from "gatsby";
import Button from "../common/buttons/Button";
import PortableText from "react-portable-text";

export default function PanamaAdventure({
  data,
  bgUrl,
  bgSize,
  minH,
  show = "show",
}) {
  return (
    <div
      className={`relative block w-full box-border ${bgSize || ""} ${show}`}
      style={{
        backgroundImage: `${bgUrl}`,
      }}
    >
      <div className="max-w-[1140px] w-full mx-auto block">
        <div
          className={`w-full flex flex-col flex-wrap justify-center p-5 ${minH}`}
        >
          <div className="relative block max-w-[1140px] w-full mx-auto">
            {/* <FaHome fill={"white"} size={65} className="mx-auto" /> */}
            {data?.svg && (
              <Link to={data?.link}>
                <div
                  dangerouslySetInnerHTML={{ __html: data.svg }}
                  className="flex justify-center"
                />
              </Link>
            )}
            <div className="block py-5 text-white">
              <p className="text-3xl sm:text-[50px] leading-[45px] sm:leading-[60px] font-bold text-center py-5">
                {data?.heading}
              </p>
              <p className="text-2xl sm:text-4xl font-bold uppercase text-center mb-3 leading-10 tracking-[2px] pb-5">
                {data?.subHeading}
              </p>
            </div>
          </div>

          {data?._rawDetail && (
            <PortableText
              content={data?._rawDetail}
              className={
                "mb-5 text-base font-semibold leading-[26px] text-white  block-content max-w-none"
              }
            />
          )}

          {/* Buttons Container */}
          <div className="w-[95%] mx-auto pt-[50px]">
            <div className="flex flex-row flex-wrap max-w-full align-top items-stretch justify-around">
              {/* Button 1 */}
              {data?.ctaButton1 && (
                <div
                  className="w-full sm:w-custom-sm lg:w-custom-lg mb-[10px]
              flex flex-col flex-wrap justify-start relative"
                >
                  <div className="relative block pt-5 pb-0 md:pb-10 m-[1px]">
                    <Button
                      btnText={data?.ctaButton1?.title}
                      btnLink={data?.ctaButton1?.link}
                      btnType={data?.ctaButton1?.variant}
                      linkType={data?.ctaButton1?.type}
                      formId={data?.ctaButton1?.formId}
                      downloadLink={data?.ctaButton1?.downloadLink}
                    />
                  </div>
                </div>
              )}
              {/* Button 2 */}

              {data?.ctaButton2 && (
                <div
                  className="w-full sm:w-custom-sm lg:w-custom-lg mb-[10px]
              flex flex-col flex-wrap justify-start relative"
                >
                  <div className="relative block pt-5 pb-0 md:pb-10 m-[1px]">
                    <Button
                      btnText={data?.ctaButton2?.title}
                      btnLink={data?.ctaButton2?.link}
                      btnType={data?.ctaButton2?.variant}
                      linkType={data?.ctaButton2?.type}
                      formId={data?.ctaButton2?.formId}
                      downloadLink={data?.ctaButton2?.downloadLink}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
